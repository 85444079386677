<template>
  <div class="payment">
    <div class="py-2">
      <h2 class="text-center text-2xl">การชำระเงิน</h2>
    </div>
    <div class="p-layout">
      <div class="payment-content">
        <v-row v-bind="{ justify: 'space-between' }" class="px-8">
          <v-col cols="6"
            ><span class="font-semibold text-md">ราคารวม</span></v-col
          >
          <v-col cols="6" class="text-right"
            ><span class="font-semibold text-md"
              >{{ priceSummary | currency }} -</span
            ></v-col
          >
        </v-row>
      </div>
      <div class="payment-content-2 mt-3">
        <span class="px-4 text-detail-payment-delivery"
          >เริ่มจัดส่งตั้งแต่วันที่ 29 เมษายน 65 เป็นต้นไป
          *ตามเงื่อนไขที่บริษัทกำหนด</span
        >
        <div class="mt-8">
          <h2 class="text-2xl">วิธีชำระเงิน</h2>
          <hr class="mt-2 gray" />
          <div class="channel-payment">
            <ButtonPayment
              data-pptr="payment_type_id"
              :hasError="isErrorRequirePayment"
              @click="onSelectPaymentMethod(P_KBANK_INSTALLMENT)"
              name="ผ่อนชำระด้วยบัตรเครดิตกสิกร"
              label="KBank ผ่อน 0% นานสูงสุด 10 เดือน"
              :image="require('@/assets/KBank-logo.jpg')"
              :selected="paymentId === P_KBANK_INSTALLMENT"
            />
            <ButtonPayment
              data-pptr="payment_type_id"
              :hasError="isErrorRequirePayment"
              @click="onSelectPaymentMethod(P_KBANK_FULL)"
              name="ชำระเงินด้วยบัตร VISA/Master Card"
              :image="require('@/assets/visa.png')"
              :selected="paymentId === P_KBANK_FULL"
            />
          </div>
        </div>

        <v-row v-bind="{ justify: 'center' }" class="mt-16">
          <div class="d-flex align-center">
            <v-checkbox
              v-model="checkAgree"
              color="#ffd400"
            ></v-checkbox
            ><span class="pointer" @click="checkAgree = !checkAgree"
              >ยอมรับ<span
                class="text-blue"
                @click.stop="
                  isShowTerm = false
                  isShowTerm = true
                  checkAgree = true
                "
                >ข้อตกลงการสั่งซื้อและเงื่อนไข</span
              >ของบริษัท</span
            >
          </div>
        </v-row>
        <v-row v-bind="{ justify: 'center' }" class="mt-12">
          <Recaptcha @validate="validate" />
        </v-row>
        <v-row v-bind="{ justify: 'center' }" class="mt-12">
          <div
            v-if="!isSoldOut"
            class="btn-bnn-submit"
            v-bind:class="{
              disabled: !checkAgree || !validateRecaptcha,
              isLoading: loading,
            }"
            @click="
              checkAgree && validateRecaptcha ? onSubmit() : false
            "
          >
            <div class="d-flex align-center justify-center">
              <div class="mr-2">
                <v-progress-circular
                  v-if="loading"
                  :width="3"
                  :size="20"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </div>
              <span>ชำระเงิน</span>
            </div>
          </div>
          <div v-else class="btn-bnn-submit disabled">
            <div class="d-flex align-center justify-center">
              <div class="mr-2">
                <v-progress-circular
                  v-if="loading"
                  :width="3"
                  :size="20"
                  color="white"
                  indeterminate
                ></v-progress-circular>
              </div>
              <span class="text-red">Sold Out</span>
            </div>
          </div>
        </v-row>
      </div>
      <Term :dialog="isShowTerm" @close="isShowTerm = false" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ButtonPayment from './ButtonPayment'
import Term from './Term'
import {
  P_KBANK_INSTALLMENT,
  P_KBANK_FULL,
} from '@/config/payment.json'
import Recaptcha from '@/components/Recaptcha'

export default {
  name: 'Payment',

  props: {
    paymentId: Number,
    loading: Boolean,
    isErrorRequirePayment: Boolean,
  },

  components: {
    ButtonPayment,
    Term,
    Recaptcha,
  },

  computed: {
    ...mapGetters({
      //   haveDeposit: 'cart/haveDeposit',
      //   priceDeposit: 'cart/priceDeposit',
      priceSummary: 'cart/priceSummary',
      isSoldOut: 'model/getSoldOut',
      //   cartItems: 'cart/cartItems',
    }),
  },

  data() {
    return {
      validateRecaptcha: false,
      checkAgree: false,
      isShowTerm: false,
      P_KBANK_FULL: P_KBANK_FULL,
      P_KBANK_INSTALLMENT: P_KBANK_INSTALLMENT,
    }
  },

  methods: {
    validate(success) {
      this.validateRecaptcha = success
    },
    onSubmit() {
      if (!this.loading) this.$emit('onSubmit')
    },
    onSelectPaymentMethod(value) {
      this.$emit('onSelectPaymentMethod', value)
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl'
.pointer
    cursor pointer
.payment
    background white
    margin-top 1rem
.p-layout
    display flex
    flex-direction column
    align-content center
    align-items center
.payment-content-2
    width 80%
    @media medium
        width 100%
        padding 0 1rem
    @media small
        width 100%
        padding 0 1rem
.payment-content
    background black
    color white
    width 80%
    padding 1rem
    @media medium
        width 100%
    @media small
        width 100%
.channel-payment
    display flex
    align-items center
    margin-top 3rem
    width 100%
    flex-wrap wrap
.btn-bnn-submit
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 1rem 6rem
    max-width 350px
    font-weight bold
    cursor pointer
    @media small
      padding 0.6rem 6rem
    @media medium
      padding 1rem 6rem
.btn-bnn-submit.disabled
    background $color-gray-100
    opacity 0.7
    cursor not-allowed
.text-detail-payment-delivery
    font-size 1rem
    @media medium
        font-size 0.8rem
    @media small
        font-size 0.8rem
.isLoading
  cursor not-allowed !important
  opacity 0.6
</style>
