<template>
  <div>
    <div data-aos="fade-up">
      <div class="customer-form">
        <v-row
          class="w-full"
          v-bind="{ justify: 'center', 'no-gutters': true }"
        >
          <h2 class="py-8">ที่อยู่ ใบเสร็จและวิธีการจัดส่ง</h2>
        </v-row>
        <div class="d-flex justify-center">
          <form class="form-customer-layout">
            <v-col cols="12" class="text-dark-gray">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    ชื่อ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.first_name"
                    background-color="white"
                    data-pptr="first_name"
                    class="input-customer text-sm mt-2"
                    :error="$v.form.first_name.$error"
                    outlined
                    dense
                    type="text"
                    ref="first_name"
                    @change="persist('firstName', $event)"
                    placeholder="ชื่อ"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    นามสกุล <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.last_name"
                    background-color="white"
                    data-pptr="last_name"
                    class="input-customer text-sm mt-2"
                    :error="$v.form.last_name.$error"
                    outlined
                    dense
                    type="text"
                    ref="last_name"
                    @change="persist('lastName', $event)"
                    placeholder="นามสกุล"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    อีเมล <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.email"
                    background-color="white"
                    data-pptr="email"
                    class="input-customer text-sm mt-2"
                    :error="$v.form.email.$error"
                    outlined
                    dense
                    type="email"
                    ref="email"
                    @change="persist('email', $event)"
                    placeholder="อีเมล"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    เบอร์โทรศัพท์ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    @keypress="isNumber($event)"
                    v-model="form.phone"
                    background-color="white"
                    data-pptr="phone"
                    class="input-customer text-sm mt-2"
                    :error="$v.form.phone.$error"
                    outlined
                    dense
                    maxlength="10"
                    type="tel"
                    ref="phone"
                    @change="persist('phoneNumber', $event)"
                    placeholder="เบอร์โทรศัพท์"
                  />
                </v-col>
                <div class="my-4"></div>
                <v-col cols="12" md="12" class="pb-0">
                  <label class="mb-18">
                    บ้าน/อาคาร เลขที่ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    v-model="form.address"
                    background-color="white"
                    data-pptr="address"
                    class="input-customer text-sm"
                    :error="$v.form.address.$error"
                    outlined
                    dense
                    type="text"
                    ref="address"
                    @change="persist('address', $event)"
                    placeholder="บ้านเลขที่,ห้อง,หมู่บ้าน,ชื่ออาคาร,ตึก,คอนโด"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    จังหวัด <span class="text-red">*</span>
                  </label>
                  <v-select
                    :error="$v.form.province.$error"
                    v-model="form.province"
                    background-color="white"
                    class="text-sm"
                    :class="{ 'has-error': $v.form.province.$error }"
                    :dense="true"
                    :items="provinceData"
                    data-pptr="province"
                    item-text="province"
                    item-value="province"
                    @change="persist('province', $event)"
                    placeholder="จังหวัด"
                    ref="province"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    เขต/อำเภอ <span class="text-red">*</span>
                  </label>
                  <v-select
                    :error="$v.form.district.$error"
                    v-model="form.district"
                    background-color="white"
                    class="text-sm"
                    :class="{ 'has-error': $v.form.district.$error }"
                    :dense="true"
                    :items="districtData"
                    data-pptr="amphoe"
                    item-text="amphoe"
                    item-value="amphoe"
                    @change="persist('district', $event)"
                    placeholder="เขต/อำเภอ"
                    ref="district"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    แขวง/ตำบล <span class="text-red">*</span>
                  </label>
                  <v-select
                    :error="$v.form.sub_district.$error"
                    v-model="form.sub_district"
                    background-color="white"
                    class="text-sm"
                    :class="{
                      'has-error': $v.form.sub_district.$error,
                    }"
                    :dense="true"
                    :items="subDistrictData"
                    data-pptr="sub_district"
                    item-text="district"
                    item-value="district"
                    @change="persist('sub_district', $event)"
                    placeholder="แขวง/ตำบล"
                    ref="sub_district"
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="pb-0">
                  <label class="mb-18">
                    รหัสไปรษณีย์ <span class="text-red">*</span>
                  </label>
                  <v-text-field
                    @keypress="isNumber($event)"
                    v-model="form.postcode"
                    background-color="white"
                    data-pptr="postcode"
                    :error="$v.form.postcode.$error"
                    class="input-customer text-sm"
                    outlined
                    dense
                    type="text"
                    @change="persist('postcode', $event)"
                    ref="postcode"
                    maxlength="5"
                    placeholder="รหัสไปรษณีย์"
                  />
                </v-col>
              </v-row>

              <v-row class="w-full">
                <v-col cols="12" md="6">
                  <h3 class="text-xl font-semibold">
                    <v-checkbox
                      :input-value="form.checkReceipt"
                      :true-value="true"
                      color="#ffd400"
                      label="ต้องการรับใบกำกับภาษี / แก้ไขใบเสร็จ"
                      background-color="#f5f5f7"
                      @click="checkTax(0)"
                    ></v-checkbox>
                  </h3>
                </v-col>
                <v-col
                  cols="12"
                  v-if="form.checkReceipt"
                  class="pb-0"
                >
                  <v-row v-show="form.checkReceipt" no-gutters>
                    <v-col
                      cols="12"
                      md="3"
                      class="pt-0 align-self-center"
                    >
                      <v-checkbox
                        class="mt-0"
                        :input-value="form.checkTaxPersonal"
                        :label="`ใบกำกับภาษีส่วนบุคคล`"
                        :true-value="true"
                        color="#ffd400"
                        background-color="#f5f5f7"
                        @click="checkTax(1)"
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                      class="pt-0 align-self-center"
                    >
                      <v-checkbox
                        class="mt-0"
                        :input-value="form.checkTaxJuristic"
                        :label="`ใบกำกับภาษีนิติบุคคล`"
                        :true-value="true"
                        color="#ffd400"
                        background-color="#f5f5f7"
                        @click="checkTax(2)"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  v-if="form.checkReceipt"
                  class="pb-0"
                >
                  <v-row v-if="form.checkReceipt">
                    <v-col
                      v-if="
                        form.checkTaxPersonal || form.checkTaxJuristic
                      "
                      cols="12"
                      md="12"
                    >
                      <label class="mb-18">
                        เลขที่ประจำตัวผู้เสียภาษี
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        @keypress="isNumber($event)"
                        v-model="form.tax_id"
                        background-color="white"
                        data-pptr="tax_id"
                        class="input-customer text-sm"
                        :error="$v.form.tax_id.$error"
                        ref="tax_id"
                        outlined
                        dense
                        maxlength="13"
                        type="tel"
                        placeholder="เลขที่ประจำตัวผู้เสียภาษี"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.checkTaxJuristic"
                      cols="12"
                      md="6"
                      class="pb-0"
                    >
                      <label class="mb-18">
                        ชื่อ
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxFirstName"
                        background-color="white"
                        data-pptr="taxFirstName"
                        class="input-customer text-sm"
                        :error="$v.form.taxFirstName.$error"
                        outlined
                        dense
                        ref="taxFirstName"
                        maxlength="100"
                        type="text"
                        placeholder="ชื่อ"
                      />
                    </v-col>
                    <v-col
                      v-if="!form.checkTaxJuristic"
                      cols="12"
                      md="6"
                      class="pb-0"
                    >
                      <label class="mb-18">
                        นามสกุล
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxLastName"
                        background-color="white"
                        data-pptr="taxLastName"
                        class="input-customer text-sm"
                        :error="$v.form.taxLastName.$error"
                        outlined
                        dense
                        maxlength="100"
                        ref="taxLastName"
                        type="text"
                        placeholder="นามสกุล"
                      />
                    </v-col>
                    <v-col
                      v-if="form.checkTaxJuristic"
                      cols="12"
                      md="6"
                      class="pb-0"
                    >
                      <label class="mb-18">
                        ชื่อบริษัท
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxCompanyName"
                        background-color="white"
                        data-pptr="taxCompanyName"
                        class="input-customer text-sm"
                        :error="$v.form.taxCompanyName.$error"
                        outlined
                        dense
                        maxlength="100"
                        type="text"
                        ref="taxCompanyName"
                        placeholder="ชื่อบริษัท"
                      />
                    </v-col>
                    <v-col
                      v-if="form.checkTaxJuristic"
                      cols="12"
                      md="6"
                      class="pb-0"
                    >
                      <label class="mb-18">
                        รหัสสาขา
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxCompanyCode"
                        background-color="white"
                        data-pptr="taxCompanyCode"
                        class="input-customer text-sm"
                        :error="$v.form.taxCompanyCode.$error"
                        outlined
                        dense
                        maxlength="100"
                        type="text"
                        ref="taxCompanyCode"
                        placeholder="รหัสสาขา"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        บ้าน/อาคาร/เลขที่
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxAddress1"
                        background-color="white"
                        data-pptr="taxAddress1"
                        class="input-customer text-sm"
                        :error="$v.form.taxAddress1.$error"
                        outlined
                        dense
                        maxlength="100"
                        type="text"
                        @change="persist('taxAddress1', $event)"
                        ref="taxAddress1"
                        placeholder="บ้าน/อาคาร/เลขที่"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        หมู่บ้าน/อาคาร/สถานที่
                      </label>
                      <v-text-field
                        v-model="form.taxAddress2"
                        background-color="white"
                        data-pptr="taxAddress2"
                        class="input-customer text-sm"
                        outlined
                        dense
                        maxlength="100"
                        type="text"
                        @change="persist('taxAddress2', $event)"
                        ref="taxAddress2"
                        placeholder="หมู่บ้าน/อาคาร/สถานที่"
                      />
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0">
                      <label class="mb-18">
                        ที่อยู่/ถนน/ซอย
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        v-model="form.taxAddress3"
                        background-color="white"
                        data-pptr="taxAddress3"
                        class="input-customer text-sm"
                        :error="$v.form.taxAddress3.$error"
                        outlined
                        dense
                        maxlength="100"
                        type="text"
                        @change="persist('taxAddress3', $event)"
                        ref="taxAddress3"
                        placeholder="ที่อยู่/ถนน/ซอย"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        จังหวัด
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="form.taxProvince"
                        background-color="white"
                        class="text-sm"
                        :class="{
                          'has-error': $v.form.taxProvince.$error,
                        }"
                        :dense="true"
                        :items="provinceData"
                        data-pptr="taxProvince"
                        item-text="province"
                        item-value="province"
                        :error="$v.form.taxProvince.$error"
                        @change="persist('taxProvince', $event)"
                        placeholder="จังหวัด"
                        ref="taxProvince"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        เขต/อำเภอ
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="form.taxDistrict"
                        background-color="white"
                        class="text-sm"
                        :class="{
                          'has-error': $v.form.taxDistrict.$error,
                        }"
                        :error="$v.form.taxDistrict.$error"
                        :dense="true"
                        :items="taxDistrictData"
                        data-pptr="taxDistrict"
                        item-text="amphoe"
                        item-value="amphoe"
                        @change="persist('taxDistrict', $event)"
                        placeholder="เขต/อำเภอ"
                        ref="taxDistrict"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        แขวง/ตำบล
                        <span class="text-red">*</span>
                      </label>
                      <v-select
                        v-model="form.taxSubDistrict"
                        background-color="white"
                        class="text-sm"
                        :class="{
                          'has-error': $v.form.taxSubDistrict.$error,
                        }"
                        :error="$v.form.taxSubDistrict.$error"
                        :dense="true"
                        :items="taxSubDistrictData"
                        data-pptr="taxSubDistrict"
                        item-text="district"
                        item-value="district"
                        @change="persist('taxSubDistrict', $event)"
                        placeholder="แขวง/ตำบล"
                        ref="taxSubDistrict"
                        outlined
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        รหัสไปรษณีย์
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        @keypress="isNumber($event)"
                        v-model="form.taxPostcode"
                        background-color="white"
                        data-pptr="taxPostcode"
                        :error="$v.form.taxPostcode.$error"
                        class="input-customer text-sm"
                        outlined
                        dense
                        type="text"
                        @change="persist('taxPostcode', $event)"
                        ref="taxPostcode"
                        maxlength="5"
                        placeholder="รหัสไปรษณีย์"
                      />
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0">
                      <label class="mb-18">
                        เบอร์โทรศัพท์
                        <span class="text-red">*</span>
                      </label>
                      <v-text-field
                        @keypress="isNumber($event)"
                        v-model="form.taxPhoneNumber"
                        background-color="white"
                        data-pptr="taxPhoneNumber"
                        class="input-customer text-sm"
                        :error="$v.form.taxPhoneNumber.$error"
                        outlined
                        dense
                        maxlength="10"
                        type="tel"
                        ref="taxPhoneNumber"
                        placeholder="เบอร์โทรศัพท์"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </form>
        </div>
        <div class="d-flex justify-center">
          <div class="form-customer-layout">
            <hr class="gray" />
            <Delivery
              :hasError="$v.form.delivery.$error"
              :postCode="form.postcode"
              @onSelectDelivery="onSelectDelivery"
            />
          </div>
        </div>
      </div>
    </div>
    <div data-aos="fade-up">
      <div>
        <Payment
          :loading="loadingPayment"
          :paymentId="paymentId"
          :isErrorRequirePayment="$v.form.payment_type_id.$error"
          @onSubmit="onSubmit()"
          @onSelectPaymentMethod="selectPayment"
        />
      </div>
    </div>
    <!-- <OutStock v-if="isOutStock" /> -->
    <OutStock v-if="isSoldOut" />
    <Modal
      :dialog="isOutStock"
      :message="outSockMessage"
      @onClose="onClickCloseOutStock"
    />
  </div>
</template>

<script>
import { showKBankEmbedUI } from '@/utils/kbank'
import axios from 'axios'
import { mapGetters, mapState } from 'vuex'
import Delivery from './Delivery'
import Modal from './Modal'
import Payment from './Payment'
import OutStock from './OutStock'
import { validationMixin } from 'vuelidate'
import {
  P_KBANK_INSTALLMENT,
  P_KBANK_FULL,
} from '@/config/payment.json'
import {
  required,
  email,
  maxLength,
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators'

export default {
  name: 'CustomerForm',

  components: {
    Delivery,
    Payment,
    Modal,
    OutStock,
  },

  mixins: [validationMixin],

  computed: {
    ...mapState({
      selectDelivery: state => state.cart.delivery,
      recaptchaKey: state => state.recaptcha.key,

      //   selectSKU: state => state.model.selectSKU,
    }),
    ...mapGetters({
      // haveDeposit: 'cart/haveDeposit',
      // priceDeposit: 'cart/priceDeposit',
      bundles: 'model/getBundles',
      productItem: 'cart/productItem',
      bundleItems: 'cart/bundleItems',
      deliveryItems: 'cart/deliveryItems',
      isSoldOut: 'model/getSoldOut',
    }),
    provinceData() {
      return this.$store.getters['thailandStore/getProvince']
    },

    districtData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.province,
      )
    },
    taxDistrictData() {
      return this.$store.getters['thailandStore/getDistrict'](
        this.form.taxProvince,
      )
    },
    zipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.sub_district,
      )
    },
    taxZipcodeData() {
      return this.$store.getters['thailandStore/getZipcode'](
        this.form.taxSubDistrict,
      )
    },

    taxSubDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.taxProvince,
        this.form.taxDistrict,
      )
    },

    subDistrictData() {
      return this.$store.getters['thailandStore/getSubDistrict'](
        this.form.province,
        this.form.district,
      )
    },
  },

  data() {
    return {
      paymentId: null,
      isOutStock: false,
      loadingPayment: false,
      outSockMessage: '',
      form: {
        // id_card: localStorage.getItem('idCard') || '',
        isOutStock: false,
        product_id: null,
        branch_time_id: undefined,
        bundle_products: [],
        payment_type_id: null,
        is_delivery: true,
        delivery: undefined,

        first_name: localStorage.getItem('firstName') || '',
        last_name: localStorage.getItem('lastName') || '',
        phone: localStorage.getItem('phoneNumber') || '',
        email: localStorage.getItem('email') || '',
        address: localStorage.getItem('address') || '',
        sub_district: localStorage.getItem('sub_district') || '',
        district: localStorage.getItem('district') || '',
        province: localStorage.getItem('province') || '',
        postcode: localStorage.getItem('postcode') || '',
        checkReceipt: false,
        checkTaxPersonal: false,
        checkTaxJuristic: false,
        tax_id: '',
        taxFirstName: '',
        taxLastName: '',
        taxCompanyName: '',
        taxCompanyCode: '',
        taxAddress1: localStorage.getItem('taxAddress1') || '',
        taxAddress2: localStorage.getItem('taxAddress2') || '',
        taxAddress3: localStorage.getItem('taxAddress3') || '',
        taxProvince: localStorage.getItem('taxProvince') || '',
        taxDistrict: localStorage.getItem('taxDistrict') || '',
        taxSubDistrict: localStorage.getItem('taxSubDistrict') || '',
        taxPostcode: localStorage.getItem('taxPostcode') || '',
        taxPhoneNumber: '',
        billing_address: undefined,

        // other
        // for_work: localStorage.getItem('for_work'),

        /**
         * dev
         */
        // id_card: '1231231231231',
        // product_id: null,
        // branch_time_id: '',
        // bundle_products: [],
        // payment_type_id: null,
        // is_delivery: false,

        // first_name: '123123',
        // last_name: '12312',
        // phone: '12312',
        // email: 'aa@aaa.com',
        // address: '123123',
        // sub_district: 'คลองสาน',
        // district: 'คลองสาน',
        // province: 'กรุงเทพมหานคร',
        // postcode: '12323',

        // // other
        // for_work: false,
      },
    }
  },

  validations: {
    form: {
      // id_card: {
      //   required,
      //   minLength: minLength(6),
      //   maxLength: maxLength(20),
      // },
      first_name: { required, maxLength: maxLength(40) },
      last_name: { required, maxLength: maxLength(40) },
      phone: { required, numeric, maxLength: maxLength(20) },
      email: { required, email, maxLength: maxLength(40) },
      address: { required, maxLength: maxLength(100) },
      sub_district: { required, maxLength: maxLength(100) },
      district: { required, maxLength: maxLength(100) },
      province: { required, maxLength: maxLength(100) },
      postcode: { required, maxLength: maxLength(5) },
      delivery: { required },
      tax_id: {
        required: requiredIf(function() {
          return (
            this.form.checkTaxPersonal || this.form.checkTaxJuristic
          )
        }),
      },
      taxFirstName: {
        required: requiredIf(function() {
          return (
            (this.form.checkReceipt || this.form.checkTaxPersonal) &&
            !this.form.checkTaxJuristic
          )
        }),
      },
      taxLastName: {
        required: requiredIf(function() {
          return (
            (this.form.checkReceipt || this.form.checkTaxPersonal) &&
            !this.form.checkTaxJuristic
          )
        }),
      },
      taxCompanyName: {
        required: requiredIf(function() {
          return this.form.checkTaxJuristic
        }),
      },
      taxCompanyCode: {
        required: requiredIf(function() {
          return this.form.checkTaxJuristic
        }),
      },
      taxAddress1: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      taxAddress3: {
        required: requiredIf(function() {
          //   return (
          //     this.form.checkReceipt ||
          //     this.form.checkTaxPersonal ||
          //     this.form.checkTaxJuristic
          //   )
          return false
        }),
      },
      taxProvince: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      taxDistrict: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      taxSubDistrict: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      taxPostcode: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      taxPhoneNumber: {
        required: requiredIf(function() {
          return (
            this.form.checkReceipt ||
            this.form.checkTaxPersonal ||
            this.form.checkTaxJuristic
          )
        }),
      },
      payment_type_id: { required },
    },
  },

  watch: {
    'form.province'() {
      this.form.sub_district = ''
      localStorage.setItem('sub_district', '')
      this.form.district = ''
      localStorage.setItem('district', '')
      this.form.postcode = ''
      localStorage.setItem('postcode', '')
    },
    'form.district'() {
      this.form.sub_district = ''
      localStorage.setItem('sub_district', '')
      this.form.postcode = ''
      localStorage.setItem('postcode', '')
    },
    'form.sub_district'() {
      this.form.postcode = this.zipcodeData
      localStorage.setItem('postcode', this.zipcodeData)
    },
    'form.taxProvince'() {
      this.form.taxDistrict = ''
      localStorage.setItem('taxDistrict', '')
      this.form.taxSubDistrict = ''
      localStorage.setItem('taxSubDistrict', '')
      this.form.taxPostcode = ''
      localStorage.setItem('taxPostcode', '')
    },
    'form.taxDistrict'() {
      this.form.taxSubDistrict = ''
      localStorage.setItem('taxSubDistrict', '')
      this.form.taxPostcode = ''
      localStorage.setItem('taxPostcode', '')
    },
    'form.taxSubDistrict'() {
      this.form.taxPostcode = this.taxZipcodeData
      localStorage.setItem('taxPostcode', this.taxZipcodeData)
    },
  },

  mounted() {},

  methods: {
    persist(key, value) {
      localStorage.setItem(key, value)
    },
    isNumber(evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    selectPayment(value) {
      this.paymentId = value
      this.prepare()
      this.$v.form.payment_type_id.$touch()
    },
    onSelectDelivery() {
      this.prepare()
    },
    prepare() {
      // add product data
      let bundleSelect = []
      this.bundles?.forEach(i => {
        let has = false
        if (this.bundleItems.find(b => b.sku === i.sku)) {
          has = true
        }
        if (has) {
          bundleSelect.push(
            this.bundleItems.filter(b => b.sku === i.sku)[0],
          )
        }
      })
      this.form.product_id = this.productItem.id
      this.form.bundle_products = [
        ...bundleSelect,
        ...this.deliveryItems,
      ]
      this.form.delivery = this.selectDelivery
      // this.form.is_delivery = this.delivery
      this.form.payment_type_id = this.paymentId
    },

    async onSubmit() {
      await this.submit()
    },

    async onClickCloseOutStock() {
      this.isOutStock = false
      await this.$store.dispatch('model/getModel', true)
      await this.$store.dispatch('model/reset')
      await this.$store.dispatch('bundle/reset')
      window.scrollTo({
        top: 0,
      })
    },

    async submit() {
      this.prepare()
      this.$v.$touch()
      if (this.$v.$invalid) {
        for (let key in Object.keys(this.$v.form)) {
          const input = Object.keys(this.$v.form)[key]
          if (input.includes('$')) return false
          if (this.$v.form[input].$error) {
            var elmnt = document.querySelector(
              `[data-pptr='${input}']`,
            )

            elmnt.scrollIntoView({
              // behavior: 'smooth',
              block: 'center',
            })
            if (this?.$refs[input]?.focus) {
              setTimeout(() => this?.$refs[input]?.focus(), 300)
            }
            break
          }
        }
      }
      if (this.$v.$error) {
        //  console.log('error')
        return
      }

      this.loadingPayment = true

      try {
        this.outSockMessage = ''
        const res = await this.doCreateOrder()
        const data = res.data
        switch (this.paymentId) {
          // case PAYMENT_2C2P_DEP:
          // case PAYMENT_2C2P_INSTALLMENT:
          // case PAYMENT_UOB_2C2P_FULL:
          // case PAYMENT_CREDIT_CITI:
          //   await this.doPayWith2C2P(data.uuid)
          //   break
          // case PAYMENT_KBANK_DEP:
          case P_KBANK_INSTALLMENT:
          case P_KBANK_FULL:
            await this.doPayWithKBank(data.uuid)
            break
          // case PAYMENT_BAY:
          // case PAYMENT_FIRST_CHICE:
          //   await this.doPayWithBay(data.uuid)
          //   break
        }

        setTimeout(() => {
          this.loadingPayment = false
        }, 5000)
      } catch (err) {
        // console.log(err)
        // setTimeout(() => {
        //   this.$emit('loading', false)
        // }, 5000)
        // this.$store.dispatch('branchStore/getRefetchBranch')
        if (err?.response?.data?.message.includes(`หมด`)) {
          this.isOutStock = true
          this.outSockMessage = err?.response?.data?.message
          ///// Reset ////
          await this.$store.dispatch('model/getModel', true)
          await this.$store.dispatch('model/reset')
          await this.$store.dispatch('bundle/reset')
          window.scrollTo({
            top: 0,
          })
          ///// Reset ////
        } else {
          alert(
            err?.response?.data?.message ||
              'ขออภัยในความไม่สะดวก กรุณาทำรายการใหม่อีกครั้ง',
          )
        }

        this.loadingPayment = false
        return
      }
    },

    doCreateOrder() {
      return axios
        .post('/orders', {
          ...this.form,
          // branch_time_id: this.form.is_delivery
          //   ? undefined
          //   : this.form.branch_time_id,
          recaptcha_token: this.recaptchaKey,
          billing_address: this.billingAddress(),
        })
        .then(res => {
          this.$store.commit('recaptcha/setReset', true)

          setTimeout(() => {
            this.$store.commit('recaptcha/setReset', false)
          }, 3000)
          return res.data
        })
    },

    async doPayWithKBank(orderId) {
      const params = {
        order_id: orderId,
        //html: 0,
        smartpay: this.form.payment_type_id < 5 ? 0 : 1,
      }

      const body = await axios
        .post('/payments/kbank', params)
        .then(res => res.data)

      const embeds = {
        ref_id: body?.data.ref_id,
        merchant_id: body?.data.merchant_id,
        apiKey: body?.data.public_apikey,
        amount: body?.data.amount,
        currency: body?.data.currency,
        smartpayId: body?.data.smartpay_id,
        smartpayTerm: body?.data.smartpay_term,
        scriptUrl: body?.data.script_url,
        endpoint: body?.data.action_url,
      }
      showKBankEmbedUI(embeds)
    },
    checkTax(value) {
      if (value === 0) {
        this.form.checkReceipt = !this.form.checkReceipt
        if (this.form.checkReceipt) {
          this.form.checkTaxPersonal = true
        } else {
          this.form.checkTaxPersonal = false
          this.form.checkTaxJuristic = false
        }
      } else if (value === 1) {
        this.form.checkTaxPersonal = !this.form.checkTaxPersonal
        this.form.checkTaxJuristic = false
      } else if (value === 2) {
        this.form.checkTaxJuristic = !this.form.checkTaxJuristic
        this.form.checkTaxPersonal = false
      }
    },
    billingAddress() {
      let taxInvoiceType = ''
      let is_tax_invoice = false
      if (this.form.checkTaxPersonal) taxInvoiceType = 'PERSONAL'
      if (this.form.checkTaxJuristic) taxInvoiceType = 'CORPORATION'
      is_tax_invoice =
        this.form.checkReceipt ||
        this.form.checkTaxPersonal ||
        this.form.checkTaxJuristic
      const payload = {
        is_tax_invoice,
        tax_invoice_type: taxInvoiceType,
        tax_id:
          this.form.checkTaxPersonal || this.form.checkTaxJuristic
            ? this.form.tax_id
            : undefined,
        company_name: this.form.checkTaxJuristic
          ? this.form.taxCompanyName
          : undefined,
        company_code: this.form.checkTaxJuristic
          ? this.form.taxCompanyCode
          : undefined,
        first_name: !this.form.checkTaxJuristic
          ? this.form.taxFirstName
          : undefined,
        last_name: !this.form.checkTaxJuristic
          ? this.form.taxLastName
          : undefined,
        address: `${this.form.taxAddress1 || ''} ${this.form
          .taxAddress2 || ''} ${this.form.taxAddress3 || ''}`,
        sub_district: this.form.taxSubDistrict,
        district: this.form.taxDistrict,
        province: this.form.taxProvince,
        country: 'ไทย',
        postcode: this.form.taxPostcode,
        phone: this.form.taxPhoneNumber,
      }
      return is_tax_invoice ? payload : undefined
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl';
.customer-form
    background $color-bnn-gray
    padding-top 1rem
    padding-bottom 3rem
    margin-top 4rem
    width 100%
.form-customer-layout
    width 80%
    @media medium
      width 100%
      padding 0 1rem
    @media small
      width 100%
      padding 0 1rem
.v-text-field.v-text-field--enclosed .v-text-field__details
  margin-bottom 0 !important
.v-messages.theme--light
  min-height 0px !important
.v-input--selection-controls
  margin-top 0px !important
</style>
