<template>
  <v-dialog
    v-model="dialog"
    width="600"
    @input="v => v || $emit('close')"
  >
    <div class="wrap">
      <span class="text-close">
        <v-icon color="#ffd400" @click="onClickClose()"
          >mdi-close</v-icon
        >
      </span>
      <v-card>
        <v-card-text class="w-full">
          <div class="font-semibold text-center w-full py-4">
            เงื่อนไขการสั่งซื้อ PlayStation 5
          </div>
          <ul>
            <li>
              การสั่งซื้อสินค้า PlayStation 5 แบบรับสินค้าที่บ้าน
              ลูกค้าต้องชำระเงินเต็มจำนวนผ่านช่องทางออนไลน์เท่านั้น
            </li>
            <li>
              การสั่งซื้อสินค้า PlayStation 5 จำกัด 1 Set/ออเดอร์
              เท่านั้น
            </li>
            <li>
              เมื่อลูกค้าสั่งซื้อสินค้าเรียบร้อยแล้ว จะมี SMS และ
              email เพื่อยืนยันคำสั่งซื้อ และระบุวันที่เริ่มจัดส่ง
              ภายใน 3 วันทำการ (ไม่สามารถใช้ Hotmail ได้)
            </li>
            <li>
              ระยะเวลาในการสั่งซื้อ PlayStation 5 วันที่ 22 เมษายน
              2565 เวลา 11.00 น. เป็นต้นไป
            </li>
            <li>
              สินค้าจะเริ่มจัดส่ง ตั้งแต่วันที่ 29 เมษายน 2565
              เป็นต้นไป
              (ระยะเวลาในการจัดส่งขึ้นอยู่กับพื้นที่ในการจัดส่ง )
            </li>
            <li>
              การสั่งซื้อ PlayStation 5
              จะจัดส่งสินค้าตามที่อยู่ที่ลูกค้าระบุในการจัดส่งเท่านั้น
            </li>
            <li>
              บริษัทฯ ขอสงวนสิทธิ์ในการเปลี่ยนแปลงใดๆ
              โดยไม่ต้องแจ้งให้ลูกค้าทราบล่วงหน้า
            </li>
            <li>
              กรณีมีเงื่อนไขใดที่เป็นข้อพิพาท ข้อโต้แย้ง
              ข้อที่จะต้องตีความ
              บริษัทขอสงวนสิทธิ์ในการพิจารณาเงื่อนไขดังกล่าวเพียงฝ่ายเดียว
              และให้ถือเป็นที่สุด- บริษัทฯ
              สงวนสิทธิ์การสั่งจองสินค้านี้เฉพาะลูกค้าที่สั่งจองตามเงื่อนไขที่บริษัทกำหนดเท่านั้น
              ห้ามลูกค้าโอนสิทธิ์ให้กับบุคคล หรือ นิติบุคคลอื่นใด
            </li>
            <li>
              หากมีข้อสงสัยกรุณาติดต่อสอบถามข้อมูล 02 017 7788
              ทุกวันเวลาทำการ 09:00-18:00 น.
            </li>
          </ul>

          <!-- <div class="font-semibold text-center w-full py-4">
            เงื่อนไขในการให้บริการ SameDay Delivery ส่งไวในวันเดียว
          </div>
          <ul>
            <li>
              บริการ SameDay Delivery (เฉพาะกรุงเทพและระยะ 20 กม.
              จากคลังหลัก)* เฉพาะลูกค้าที่สั่งซื้อ PlayStation®5
              ในวันที่ 10 กุมภาพันธ์ 2564 เวลา 11.00 น.
              โดยมีค่าบริการจัดส่งเพิ่มเติม 350.-/ออเดอร์
            </li>
            <li>โดยประมาณการณ์ระยะ 20 กม. จาก Google Map</li>
            <li>
              โดยมีเขตพื้นที่ให้บริการดังนี้ พระนคร, ปทุมวัน,
              บางกอกน้อย, บางกอกใหญ่, ลาดพร้าว, บางรัก, บางพลัด, สาทร,
              วังทองหลาง, ดุสิต, ราชเทวี, พญาไท, ห้วยขวาง, วัฒมนา,
              ประเวศ, บางกะปิ, สวนหลวง, ธนบุรี, จตุจักร, บางซื่อ,
              ภาษีเจริญ, จอมทอง, บางคอแหลม, ยานนาวา, คลองเตย, คลองสาน,
              พระโขนง, ราษฎร์บูรณะ, ทุ่งครุ, สะพานสูง, ลาดกระบัง,
              คันนายาว, บึงกุ่ม, สัมพันธวงศ์, ป้อมปราบศัตรูพ่าย และ
              ดินแดง
            </li>
            <li>
              การจัดส่งสินค้าเร็ว เป็นไปตามเงื่อนไขการชำระเงิน
              และพื้นที่ให้บริการเท่านั้น*
            </li>
            <li>
              ระบบจะแสดงระยะเวลาในการจัดส่งสินค้า
              ในรายการสินค้าที่ลูกค้าตรวจสอบ
              ตามที่อยู่ปลายทางที่ระบุไว้และจะแสดงข้อมูลการจัดส่งแบบมาตรฐานเป็นขั้นต้น
              ในขั้นตอนสรุปการสั่งซื้อและการชำระเงิน
            </li>
            <li>
              การจัดส่งสินค้าแต่ละพื้นที่ของลูกค้าอาจได้รับสินค้าแตกต่างเวลากัน
            </li>
            <li>
              การจัดส่งสินค้าให้กับลูกค้า
              บางครั้งอาจได้รับผลกระทบเกิดความล่าช้าจากเหตุสุดวิสัย
              ทั้ง จากภัยธรรมชาติ หรือผลกระทบทางการเมือง ฯลฯ
              ซึ่งจะส่งผลทำให้เกิดความล่าช้าในการจัดส่ง
              ทางบริษัทฯจะประสานงานกับบริษัทขนส่งเอกชนเพื่อแก้ไขปัญหา
              ให้ลูกค้าได้รับสินค้าอย่างเร็วที่สุด
            </li>
            <li>
              เมื่อลูกค้าได้รับการแจ้งเรื่องจัดส่งสินค้าจาก BNN.in.th
              ที่ถูกจัดส่งไปตามที่อยู่ปลายทาง
              ตามที่ลูกค้าได้ระบุไว้ในขั้นตอนการทำรายการสั่งซื้อเท่านั้น
              โดยทางบริษัทขอสงวนสิทธิ์ในการเปลี่ยนแปลงที่อยู่ในการจัดส่ง
              หากสินค้าอยู่ในสถานะกำลังดำเนินการจัดส่งโดยบริษัทฯขนส่งเอกชน
            </li>
            <li>
              หากผู้ให้บริการขนส่งไม่สามารถติดต่อลูกค้าในขณะจัดส่งสินค้า
              ลูกค้าจะต้องติดต่อเข้ารับสินค้าด้วยตนเองที่คลังสินค้าของ
              BaNANA ภายใน 14 วันหลังจาก BaNANA
              ได้ส่งข้อความแจ้งเตือนทางอีเมลและ SMS แล้ว
              หรือหากไม่สะดวกมารับสินค้าคืนด้วยตนเอง
              ลูกค้าสามารถแจ้งขอใช้บริการส่งสินค้าคืนผ่านทาง Call
              Center ของ BaNANA ได้ที่เบอร์ 02-017-7788
              โดยลูกค้าจะเป็นผู้รับผิดชอบค่าใช้จ่ายในการขนส่งสินค้าดังกล่าวเพิ่มเติม
            </li>
            <li>
              ในกรณีที่ BaNANA ไม่ได้รับการติดต่อใดๆ จากลูกค้าภายใน 14
              วันหลังจาก BaNANA
              ได้ส่งข้อความแจ้งเตือนแล้วจะถือว่าลูกค้าได้สละสิทธิใดๆ
              ในสินค้าดังกล่าวและ BaNANA
              มีสิทธิ์ที่จะจัดการสินค้าด้วยวิธีการใดๆ ตามที่ BaNANA
              เห็นสมควรต่อไป
            </li>
            <li>
              บริษัทฯ ขอสงวนสิทธิ์ในการยกเลิก
              เปลี่ยนแปลงเงื่อนไขการจัดส่งสินค้า
              โดยไม่ต้องแจ้งให้ทราบล่วงหน้า
            </li>
          </ul> -->
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'Term',
  props: {
    dialog: Boolean,
  },

  methods: {
    onClickClose() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="stylus" scoped>
.wrap
  overflow hidden

.text-close
    cursor pointer
</style>
