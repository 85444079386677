<template>
  <div>
    <v-row
      v-bind="{ justify: 'center', 'no-gutters': true }"
      class="footer-bnn-top mt-8 align-center"
    >
      <div class="text-center">
        <span class="mx-2">Customer Service : 02-017-7788</span
        ><span class="mx-2">LineOA ID : @bnnonline</span>
      </div>
    </v-row>
    <v-row
      v-bind="{ justify: 'center', 'no-gutters': true }"
      class="footer-bnn-bottom align-center"
    >
      <img
        width="100px"
        class="bnn-logo"
        src="@/assets/playstation/logo_banana_white.svg"
      />
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl';
.footer-bnn-top
  height: 40px
  background-color: $color-gray-400
  z-index 10
  @media small
    font-size 0.7rem
.footer-bnn-bottom
  height: 200px
  background-color: black
  z-index 10
  color white
  @media small
    font-size 0.7rem
</style>
