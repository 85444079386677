<template>
  <div>
    <div class="delivery" data-pptr="delivery">
      <div
        class="delivery-layout"
        v-for="(item, index) of deliveryItems"
        :key="item.sku"
      >
        <div
          class="btn-delivery"
          v-bind:class="{
            select: delivery === 'delivery',
            'has-error': hasError,
          }"
          @click="onClick('delivery', item)"
          v-if="index === 0"
        >
          <v-col cols="12">
            <v-row v-bind="{ justify: 'center', 'no-gutters': true }">
              <v-col cols="3" class="align-self-center">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="d-flex align-items-center justify-end pr-2"
                  >
                    <div class="d-felx justify-end">
                      <img
                        width="40px"
                        height="40px"
                        class="w-full fit-contain"
                        :src="
                          require('@/assets/playstation/delivery.svg')
                        "
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="9" class="align-self-center">
                <v-row no-gutters>
                  <!-- <div class="d-flex flex-column"> -->
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-left text-md font-bold align-self-center"
                    >
                      แบบธรรมดา
                    </v-col>
                    <v-col cols="12" class="align-self-center">
                      <div class="text-left deli-detail">
                        รับสินค้าใน 1-3 วัน*
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-col cols="5" class="align-self-center">
                    <div v-if="delivery" class="text-right text-lg">
                      ฿{{ item.price_ship | currency }}
                    </div>
                    <div v-if="!delivery" class="text-right text-lg">
                      ฿{{ item.price_store | currency }}
                    </div>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </div>
        <div
          @click="checkExpress() ? onClick('express', item) : false"
          class="btn-delivery"
          v-bind:class="{
            select: delivery === 'express',
            'has-error': hasError,
            disabled: !checkExpress(),
          }"
          v-if="index === 1"
        >
          <v-col cols="12">
            <v-row v-bind="{ justify: 'center', 'no-gutters': true }">
              <v-col cols="3" class="align-self-center">
                <v-row
                  v-bind="{ justify: 'end', 'no-gutters': true }"
                >
                  <v-col
                    cols="12"
                    class="d-flex align-items-center justify-end pr-2"
                  >
                    <div class="d-felx justify-end">
                      <img
                        width="40px"
                        height="40px"
                        class="w-full fit-contain"
                        :src="
                          require('@/assets/playstation/Express_Delivery.svg')
                        "
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="9" class="align-self-center">
                <v-row no-gutters>
                  <!-- <div class="d-flex flex-column"> -->
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      class="text-left text-md font-bold align-self-center text-bnn-blue"
                    >
                      แบบส่งไวในวันเดียว *เฉพาะพื้นที่ให้บริการ
                    </v-col>
                    <v-col cols="12" class="align-self-center">
                      <div class="text-left deli-detail">
                        รับสินค้าในวันที่ 5 มี.ค. 64
                        (คิดค่าบริการเพิ่ม 350 บาท)
                      </div>
                    </v-col>
                  </v-row>
                  <!-- <v-col cols="5" class="align-self-center">
                    <div v-if="delivery" class="text-right text-lg">
                      ฿{{ item.price_ship | currency }}
                    </div>
                    <div v-if="!delivery" class="text-right text-lg">
                      ฿{{ item.price_store | currency }}
                    </div>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'Delivery',
  props: {
    hasError: Boolean,
    postCode: String,
  },

  computed: {
    ...mapState({
      delivery: state => state.cart.delivery,
      //   selectSKU: state => state.model.selectSKU,
    }),
    ...mapGetters({
      deliveryItems: 'model/getBundleDelivery',
    }),
  },
  watch: {
    postCode(val) {
      if (val?.length === 5) {
        if (val.substr(0, 2) === '10') {
          return true
        } else {
          const dlv = this.deliveryItems.filter(
            item => item.price_store == 0,
          )[0]
          this.$store.commit('cart/delivery', 'delivery')
          this.$store.dispatch('bundle/selectDelivery', dlv)
          return false
        }
      } else {
        const dlv = this.deliveryItems.filter(
          item => item.price_store == 0,
        )[0]
        this.$store.commit('cart/delivery', 'delivery')
        this.$store.dispatch('bundle/selectDelivery', dlv)
        return false
      }
    },
  },

  methods: {
    onClick(value, item) {
      this.$store.commit('cart/delivery', value)
      this.$store.dispatch('bundle/selectDelivery', item)
      this.$emit('onSelectDelivery')
      this.$forceUpdate()
    },
    checkExpress() {
      if (this.postCode?.length === 5) {
        if (this.postCode.substr(0, 2) === '10') {
          return true
        } else {
          // const dlv = this.deliveryItems.filter(
          //   item => item.price_store == 0,
          // )[0]
          // this.$store.commit('cart/delivery', 'delivery')
          // this.$store.dispatch('bundle/selectDelivery', dlv)
          return false
        }
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl';

.delivery
    display flex
    height 100%
    align-items stretch
    margin-top 3rem
    @media medium
        flex-wrap wrap
    @media small
        flex-wrap wrap
.delivery-layout
    display flex
    width 50%
    padding 0 1rem
    @media medium
        width 100%
        padding 0.5rem 1rem
    @media small
        width 100%
        padding 0.5rem 1rem
.btn-delivery
    display flex
    align-items center
    width 100%
    height 100%
    background white
    cursor pointer
    border-radius: 5px;
    border 1px solid $color-bnn-border-dark-btn

    &:hover
     border-color: $color-dark-gray;

.btn-delivery.select
         border-color: $color-bnn;
         box-shadow: 0px 2px 3px #00000029;
.btn-delivery.has-error
    border-color: red
.btn-delivery.disabled
    opacity 0.6
    cursor not-allowed
.deli-detail
    font-size 1.125rem
    @media small
       font-size 0.8rem
</style>
