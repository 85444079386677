var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"delivery",attrs:{"data-pptr":"delivery"}},_vm._l((_vm.deliveryItems),function(item,index){return _c('div',{key:item.sku,staticClass:"delivery-layout"},[(index === 0)?_c('div',{staticClass:"btn-delivery",class:{
          select: _vm.delivery === 'delivery',
          'has-error': _vm.hasError,
        },on:{"click":function($event){return _vm.onClick('delivery', item)}}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-items-center justify-end pr-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-felx justify-end"},[_c('img',{staticClass:"w-full fit-contain",attrs:{"width":"40px","height":"40px","src":require('@/assets/playstation/delivery.svg')}})])])],1)],1),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left text-md font-bold align-self-center",attrs:{"cols":"12"}},[_vm._v(" แบบธรรมดา ")]),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left deli-detail"},[_vm._v(" รับสินค้าใน 1-3 วัน* ")])])],1)],1)],1)],1)],1)],1):_vm._e(),(index === 1)?_c('div',{staticClass:"btn-delivery",class:{
          select: _vm.delivery === 'express',
          'has-error': _vm.hasError,
          disabled: !_vm.checkExpress(),
        },on:{"click":function($event){_vm.checkExpress() ? _vm.onClick('express', item) : false}}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"3"}},[_c('v-row',_vm._b({},'v-row',{ justify: 'end', 'no-gutters': true },false),[_c('v-col',{staticClass:"d-flex align-items-center justify-end pr-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-felx justify-end"},[_c('img',{staticClass:"w-full fit-contain",attrs:{"width":"40px","height":"40px","src":require('@/assets/playstation/Express_Delivery.svg')}})])])],1)],1),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-left text-md font-bold align-self-center text-bnn-blue",attrs:{"cols":"12"}},[_vm._v(" แบบส่งไวในวันเดียว *เฉพาะพื้นที่ให้บริการ ")]),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"12"}},[_c('div',{staticClass:"text-left deli-detail"},[_vm._v(" รับสินค้าในวันที่ 5 มี.ค. 64 (คิดค่าบริการเพิ่ม 350 บาท) ")])])],1)],1)],1)],1)],1)],1):_vm._e()])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }