<template>
  <div>
    <div class="d-flex justify-center" v-if="bundles.length > 0">
      <div class="bundle">
        <div class="flex justify-center mt-16">
          <h2 class="text-2xl text-center">อุปกรณ์เสริม</h2>
        </div>
        <div class="hr-title-bundle">
          <hr class="gray" />
        </div>
        <div class="accessories-content">
          <div
            v-for="(item, index) in bundles"
            :key="item.sku"
            class="accessories-layout"
          >
            <div
              :id="`accessories-${index}`"
              class="btn-accessories"
              v-bind:class="{
                selected: isSelected(item),
                disabled: item.active === false,
              }"
              @click="
                item.active === true || item.active === 1
                  ? false
                  : false
              "
            >
              <div class="d-flex align-items-center">
                <v-col cols="1" class="align-self-center">
                  <v-row no-gutters>
                    <v-col cols="12" class="align-self-center">
                      <label class="checkbox"></label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="11">
                  <v-row
                    v-bind="{ justify: 'center', 'no-gutters': true }"
                  >
                    <v-col cols="3" class="align-self-center">
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="d-flex align-items-center justify-center"
                        >
                          <img
                            class="img-bundle"
                            :src="item.image_url"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="9" class="align-self-center">
                      <v-row no-gutters>
                        <!-- <div class="d-flex flex-column"> -->
                        <v-row no-gutters>
                          <v-col
                            cols="12"
                            class="text-center text-sm font-bold align-self-center"
                          >
                            {{ item.name }}
                          </v-col>
                          <!-- hotfix remove price -->
                          <!-- <v-col cols="12" class="align-self-center">
                            <div class="text-center text-lg">
                              ราคา ฿{{ item.price_ship | currency }}
                            </div>
                          </v-col> -->
                        </v-row>
                        <!-- <v-col cols="5" class="align-self-center">
                        <div v-if="delivery" class="text-right text-lg">
                          ฿{{ item.price_ship | currency }}
                        </div>
                        <div v-if="!delivery" class="text-right text-lg">
                          ฿{{ item.price_store | currency }}
                        </div>
                      </v-col> -->
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Bundle',

  computed: {
    ...mapGetters({
      bundles: 'model/getBundles',
    }),
  },

  data() {
    return {
      //   bundles: [
      //     {
      //       sku: '190198893369',
      //       name: 'คอนโทรลเลอร์ไร้สาย DualSense',
      //       type: 'accessory',
      //       limit: '1',
      //       image_url:
      //         'https://gmedia.playstation.com/is/image/SIEPDC/dualsense-thumbnail-ps5-01-en-17jul20?$native$',
      //       price_srp: '4490',
      //       price_ship: '4265.00',
      //       price_store: '4265.00',
      //     },
      //     {
      //       sku: '1901988933691',
      //       name: 'ชุดหูฟังไร้สาย PULSE 3D',
      //       type: 'accessory',
      //       limit: '1',
      //       image_url:
      //         'https://i.expansys.net/img/b/329503/playstation-pulse-3d-wireless-headset.jpg',
      //       price_srp: '4490',
      //       price_ship: '4265.00',
      //       price_store: '4265.00',
      //     },
      //     {
      //       sku: '1901988933692',
      //       name: 'อะแดปเตอร์ PlayStation Camera',
      //       type: 'accessory',
      //       limit: '1',
      //       image_url:
      //         'https://global.support.playstation.com/resource/1603772708000/nabooFormAssets/headset@2x.png',
      //       price_srp: '4490',
      //       price_ship: '4265.00',
      //       price_store: '4265.00',
      //     },
      //   ],
    }
  },

  methods: {
    onClickSelect(item) {
      this.$store.dispatch('bundle/toggle', item)
      this.$forceUpdate()
    },

    onClickAdd(item) {
      //   this.$store.dispatch('bundle/add', item)
      this.$store.dispatch('bundle/remove', item)
      this.$forceUpdate()
    },

    onClickRemove(item) {
      this.$store.dispatch('bundle/remove', item)
      this.$forceUpdate()
    },

    unit(item) {
      return this.$store.getters['bundle/countBySKU'](item)
    },

    isSelected(item) {
      return this.unit(item) > 0
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl';
.bundle
    width 80%
    @media medium
        width 100%
    @media small
        width 100%

.accessories-content
    margin-top 24px
    display flex
    flex-wrap wrap
    justify-content center
    @media medium
       flex-wrap wrap
    @media small
       flex-wrap wrap


.accessories-layout
    width 33.33333%
    padding 1rem
    @media medium
       width 100%
       padding 0 1rem
    @media small
       width 100%
       padding 0 1rem

.hr-title-bundle
    padding 1rem
    margin-top 1.5rem

.img-bundle
  width auto;
  max-height 100px
  max-width 110%
  min-width 60px
  @media medium
    max-height 60px




.btn-counter {
  border: 1px solid;
  border-color: $color-gray-border;
  border-radius: 4px;
  padding: 0rem 0.2rem;
}

.btn-accessories {
  cursor: pointer;
  color: $color-dark-gray;
  border: 1px solid;
  border-color: $color-bnn-border-dark-btn;

  &:hover {
    border-color: $color-dark-gray;
  }

  width: 100%;
  margin-bottom: 1.2rem;
  border-radius: 4px;

  .checkbox {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $color-gray-border;
  }

  &.selected {
    border-color: $color-bnn-border;
    box-shadow: 0px 2px 3px #00000029;

    .checkbox {
      border: none;
      background: $color-bnn-border;
    }
  }
  &.disabled {
    opacity 0.6
    cursor not-allowed
  }
}

.btn-counter {
  &-add {
    width: 25px;
    height: 25px;
    text-align: center;
    align-items: start;

    &::after {
      color: $color-dark-gray-medium;
      content: '\002B';
      font-size: 20px;
      align-self: center;
      justify-content: center;
      margin-top: -3px;
      display: flex;
    }
  }

  &-remove {
    width: 25px;
    height: 25px;
    text-align: center;
    align-items: start;

    &::after {
      color: $color-dark-gray-medium;
      content: '\2212';
      font-size: 20px;
      align-self: center;
      justify-content: center;
      margin-top: -3px;
      display: flex;
    }
  }
}

.span-counter {
  padding: 0rem 0.2rem;
  align-self: center;
}
</style>
