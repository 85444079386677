<template>
  <div class="btn-payment-layout" @click="$emit('click')">
    <div
      class="btn-payment"
      v-bind:class="{ select: selected, 'has-error': hasError }"
    >
      <v-col cols="12">
        <v-row v-bind="{ justify: 'start', 'no-gutters': true }">
          <v-col cols="2" class="align-self-center">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex align-items-center justify-start"
              >
                <img
                  width="50px"
                  height="50px"
                  class="w-full fit-contain"
                  :src="image"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="align-self-center px-2">
            <v-row no-gutters>
              <!-- <div class="d-flex flex-column"> -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="text-left text-btn-payment font-bold align-self-center"
                >
                  {{ name }}
                </v-col>
                <v-col
                  v-if="label"
                  cols="12"
                  class="align-self-center"
                >
                  <div class="text-left text-btn-payment">
                    {{ label }}
                  </div>
                </v-col>
              </v-row>
              <!-- <v-col cols="5" class="align-self-center">
                    <div v-if="delivery" class="text-right text-lg">
                      ฿{{ item.price_ship | currency }}
                    </div>
                    <div v-if="!delivery" class="text-right text-lg">
                      ฿{{ item.price_store | currency }}
                    </div>
                  </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonPayment',
  props: {
    name: String,
    label: String,
    image: String,
    selected: Boolean,
    hasError: Boolean,
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl';

.btn-payment-layout
    width 50%
    padding 0 1rem
    @media medium
        width 100%
        padding 0.5rem 0
    @media small
        width 100%
        padding 0.5rem 0
.btn-payment
    width 100%
    background white
    cursor pointer
    border 1px solid $color-bnn-border-dark-btn
    border-radius: 5px;
    height: auto;
    &:hover
         border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
     border-color: $color-bnn;
     box-shadow: 0px 2px 3px #00000029;
.btn-payment.has-error
     border-color: red;
</style>
