var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.bundles.length > 0)?_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"bundle"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"accessories-content"},_vm._l((_vm.bundles),function(item,index){return _c('div',{key:item.sku,staticClass:"accessories-layout"},[_c('div',{staticClass:"btn-accessories",class:{
              selected: _vm.isSelected(item),
              disabled: item.active === false,
            },attrs:{"id":`accessories-${index}`},on:{"click":function($event){item.active === true || item.active === 1
                ? false
                : false}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"1"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"12"}},[_c('label',{staticClass:"checkbox"})])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"3"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-items-center justify-center",attrs:{"cols":"12"}},[_c('img',{staticClass:"img-bundle",attrs:{"src":item.image_url}})])],1)],1),_c('v-col',{staticClass:"align-self-center",attrs:{"cols":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center text-sm font-bold align-self-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)],1)],1)],1)],1)])])}),0)])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex justify-center mt-16"},[_c('h2',{staticClass:"text-2xl text-center"},[_vm._v("อุปกรณ์เสริม")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hr-title-bundle"},[_c('hr',{staticClass:"gray"})])
}]

export { render, staticRenderFns }