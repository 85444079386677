<template>
  <div>
    <v-row
      v-bind="{ justify: 'center', 'no-gutters': true }"
      class="w-full"
    >
      <div class="slider-playstation">
        <div class="content">
          <!-- <div v-for="item in images" :key="item.id">
            <img
              v-if="item.id === imageSelected.id"
              :src="imageSelected.img"
              width="100%"
              class="preview-img fade-in"
            />
          </div> -->
          <img
            src="@/assets/playstation/ps5_banner_april_2022_2.png"
            width="100%"
            class="preview-img fade-in"
          />
        </div>
        <div class="content">
          <div class="slider-col">
            <div class="d-flex align-center justify-center">
              <span class="text-exclusive">Exclusive</span
              ><span class="text-preorder">Pre-Order</span>
            </div>
            <div class="d-flex align-center justify-center">
              <span class="text-ps5">PlayStation®5</span>
              <img
                class="img-ps5"
                src="@/assets/playstation/PS5_logo.svg"
                width="100%"
              />
            </div>
            <!-- <v-row 
			v-bind="{ justify: 'end', align: 'center' }" 
			>
              <div @click="doPrev()">
                <span role="img" class="SVGInline">
                  <svg
                    class="SVGInline-svg slider-arrow"
                    id="S_NavLeft_S_22"
                    viewBox="0 0 22 22"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    x="0px"
                    y="0px"
                  >
                    <g id="Layer%201">
                      <path
                        d="M 7.2092 10.3975 L 12.3992 5.2178 C 12.699 4.9277 13.1692 4.9277 13.469 5.2178 C 13.759 5.5078 13.759 5.9775 13.469 6.2778 L 8.8089 10.9277 L 13.469 15.5879 C 13.759 15.8779 13.759 16.3477 13.469 16.6475 C 13.1692 16.9277 12.699 16.9277 12.3992 16.6475 L 7.219 11.4575 C 6.929 11.1577 6.929 10.6875 7.2092 10.3975 L 7.2092 10.3975 Z"
                        fill="#191818"
                      ></path>
                    </g></svg
                ></span>
              </div>
              <div>
				  <v-row 
                v-bind="{ justify: 'end', align: 'center', 'no-gutters': true }"
				 >
                  <v-col v-for="(item, idx) in images" :key="item.id">
                    <img
                      v-bind:class="{
                        selected: item.id === imageSelected.id,
                      }"
                      @click="changePreview(idx)"
                      class="object-contain thumbnail"
                      :src="item.img"
                    />
                  </v-col>
                </v-row>
              </div>
              <div @click="doNext()">
                <span role="img" class="SVGInline">
                  <svg
                    class="SVGInline-svg slider-arrow"
                    id="S_NavRight_S_22"
                    viewBox="0 0 22 22"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xml:space="preserve"
                    x="0px"
                    y="0px"
                  >
                    <g id="Layer%201">
                      <path
                        d="M 14.4775 10.3975 L 9.2876 5.2178 C 8.9878 4.9277 8.5176 4.9277 8.2178 5.2178 C 7.9277 5.5078 7.9277 5.9775 8.2178 6.2778 L 12.8779 10.9277 L 8.2178 15.5879 C 7.9277 15.8779 7.9277 16.3477 8.2178 16.6475 C 8.5176 16.9277 8.9878 16.9277 9.2876 16.6475 L 14.4678 11.4575 C 14.7578 11.1577 14.7578 10.6875 14.4775 10.3975 L 14.4775 10.3975 Z"
                        fill="#191818"
                      ></path>
                    </g></svg
                ></span>
              </div>
            </v-row> -->
            <!-- <v-row v-bind="{justify: 'center'}" class="mb-8">
              <div class="btn-more" @click="onClickMore">
                ดูเพิ่มเติม
              </div>
            </v-row> -->
          </div>
        </div>
      </div>
    </v-row>
    <div class="slider-line"></div>
  </div>
</template>

<script>
const IMAGES = [
  {
    id: 1,
    img: require('@/assets/playstation/bundles/ps5dual.png'),
  },
  {
    id: 2,
    img: require('@/assets/playstation/bundles/4948872414982.jpg'),
  },
  {
    id: 3,
    img: require('@/assets/playstation/bundles/4948872415118.jpg'),
  },
  {
    id: 4,
    img: require('@/assets/playstation/bundles/4948872415095.jpg'),
  },
]

export default {
  name: 'Slider',

  data() {
    return {
      currentIdx: 0,
      timeoutId: null,

      imageSelected: IMAGES[0],
      images: IMAGES,
    }
  },

  mounted() {
    this.updatePreview()
  },
  methods: {
    onClickMore() {
      window.open('https://www.bnn.in.th/en/p/playstation', '_blank')
    },
    changePreview(idx) {
      this.currentIdx = idx
      this.updatePreview()
    },
    doNext() {
      this.currentIdx++
      if (this.currentIdx >= this.images.length) {
        this.currentIdx = 0
      }
      this.updatePreview()
    },
    doPrev() {
      this.currentIdx--
      if (this.currentIdx < 0) {
        this.currentIdx = this.images.length - 1
      }
      this.updatePreview()
    },
    updatePreview() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }

      this.imageSelected = this.images[this.currentIdx]
      this.timeoutId = setTimeout(() => {
        this.doNext()
      }, 7000)
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl'
.slider-arrow
    width 35px
    height 35px
    @media medium
       width 60px
       height 60px
.SVGInline
    cursor pointer
.img-ps5
    margin-bottom 0
    @media medium
        margin-bottom 0
        width 30%
    @media small
        margin-bottom 0
        width 40%
.slider-playstation
    // background: transparent linear-gradient(301deg, #181C1F 0%, #2D333B 100%) 0% 0% no-repeat padding-box;
    background white
    width 80%
    // height 450px
    display flex
    flex-direction column-reverse
    margin-top 150px
    overflow hidden
    @media small
        flex-direction column-reverse
        height auto
        width 100%
        margin-top 120px
    @media medium
        flex-direction column-reverse
        height auto
        width 100%
        margin-top 120px
    align-items center
    .content
        display flex
        justify-content center
        width 100%
        @media medium
            width 100%
        @media small
            width 100%
    .content-left
        display flex
        justify-content flex-start
        width 50%
        @media medium
            width 100%
        @media small
            width 100%
    .content-right
        display flex
        justify-content flex-end
        width 50%
        @media medium
            width 100%
        @media small
            width 100%
.text-exclusive
    background $color-bnn
    font-size 2.2rem
    font-weight bold
    padding 0.25rem 1.3rem
    border-radius: 10px;
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.slider-col
    display flex
    flex-direction column
    font-size 2.2rem
    align-content center
    @media medium
        margin-top 2rem
    @media small
        margin-top 1.5rem
.text-preorder
    font-size 2.2rem
    margin-left 0.2rem
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.text-ps5
    font-size 2.2rem
    margin-left 0.2rem
    @media medium
      font-size 2rem
    @media small
      font-size 1.2rem
.preview-img
    width 100%
    padding 4rem 0
    // height 800px
    object-fit contain
    @media medium
        // height 600px
        margin -3rem 0
    @media small
        // height 450px
        margin -3rem 0
.btn-more
    background $color-bnn
    border-radius 30px;
    text-align center
    box-shadow: 0px 4px 6px #00000029;
    padding 0.5rem 4rem
    max-width 350px
    font-weight bold
    cursor pointer
    font-size 1rem
    @media small
      font-size 0.8rem
      padding 0.6rem 4rem
    @media medium
      padding 0.6rem 4rem
.thumbnail
  border: none;
  object-fit: contain;
  border-radius: 4px;
  padding: 0.1rem;
  cursor: pointer;
  margin: 0.2rem;
  width 80px
  height 80px
  @media medium
     width 120px
     height 120px
  @media small
     width 50px
     height 50px
  padding 0.4rem

  &:hover
    border: 1px solid $color-dark-gray
.thumbnail.selected
    border: 1px solid $color-bnn-border-light
.slider-line
    height 10px
    background $color-gray-100

.fade-in {
animation: fadeIn ease 2s;
-webkit-animation: fadeIn ease 2s;
-moz-animation: fadeIn ease 2s;
-o-animation: fadeIn ease 2s;
-ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-moz-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-o-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}

@-ms-keyframes fadeIn {
0% {opacity:0;}
100% {opacity:1;}
}
</style>
