<template>
  <div class="outstock-bar fade-in">
    สินค้าหมดแล้ว ขออภัยในความไม่สะดวก
  </div>
</template>

<script>
export default {
  name: 'OutStock',
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl'

.outstock-bar
    background $color-bnn-red
    width 100vw
    position fixed
    top 88px
    font-size 1.2rem
    padding 1rem 0
    color white
    text-align center
    @media small
        font-size 0.8rem
</style>
