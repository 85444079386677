<template>
  <div>
    <v-row
      v-bind="{ justify: 'center', 'no-gutters': true }"
      class="flex-column text-center py-6 mb-2 mt-2 w-full"
    >
      <div class="font-semibold">
        <h2 class="text-2xl">เลือกรุ่น</h2>
      </div>
      <div class="text-3xl">PlayStation®5</div>
      <div class="text-md">
        รับประกันตัวเครื่อง 1 ปี Sony Thailand
      </div>
    </v-row>
    <v-col>
      <div class="d-flex justify-center">
        <div class="model-content">
          <div
            :id="`model-${index}`"
            :ref="item.code"
            v-for="(item, index) in modelItemsFirstLine"
            :key="item.code"
            class="model-item"
          >
            <div
              class="btn-model"
              :class="{
                selected: !isDisabled(item) && isSelected(item),
                disabled: isDisabled(item),
              }"
              @click="isDisabled(item) ? false : onClick(item)"
            >
              <v-row
                v-bind="{ justify: 'center', 'no-gutters': true }"
              >
                <v-col class="col-model">
                  <div
                    class="text-lg font-semibold"
                    v-html="item.name_bundle || item.name"
                  ></div>
                </v-col>
              </v-row>
              <v-row
                v-bind="{ justify: 'center', 'no-gutters': true }"
              >
                <v-col class="p-4 text-lg align-self-center">
                  ราคา
                  {{ (item.price_bundle || item.price) | currency }} -
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center">
        <div class="model-content">
          <div
            :id="`model-${index}`"
            :ref="item.code"
            v-for="(item, index) in modelItemsOtherLine"
            :key="item.code"
            class="model-item"
          >
            <div
              class="btn-model"
              :class="{
                selected: !isDisabled(item) && isSelected(item),
                disabled: isDisabled(item),
              }"
              @click="isDisabled(item) ? false : onClick(item)"
            >
              <v-row
                v-bind="{ justify: 'center', 'no-gutters': true }"
              >
                <v-col class="col-model">
                  <div
                    class="text-lg font-semibold"
                    v-html="item.name_bundle || item.name"
                  ></div>
                </v-col>
              </v-row>
              <v-row
                v-bind="{ justify: 'center', 'no-gutters': true }"
              >
                <v-col class="p-4 text-lg align-self-center">
                  ราคา
                  {{ (item.price_bundle || item.price) | currency }} -
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Model',

  computed: {
    ...mapState({
      selected: state => state.model.selectModel,
      model: state => state.model.selectModel,
      //   selectSKU: state => state.model.selectSKU,
    }),
    // ...mapGetters({
    //   modelItems: 'model/getModels',
    // }),

    modelItemsFirstLine() {
      return this.modelItems.filter(i => +i.price_bundle === 19380)
    },

    modelItemsOtherLine() {
      return this.modelItems.filter(i => +i.price_bundle !== 19380)
    },

    modelItems() {
      const models = this.$store.getters['model/getModels'] || []
      const items = models.map(item => {
        let val = null
        let temp_price = '19380'
        switch (item.id) {
          case 442:
            val = `
                Sony PlayStation 5 (16,990)
                Sony DualSense Wireless Controller (2,390)
              `
            temp_price = '19380'
            break
          case 441:
            val = `
                Sony PlayStation 5 Digital Edition (13,990)
                Sony DualSense Wireless Controller (2,390)
              `
            temp_price = '16380'
            break
          case 444:
            val = `
                Sony PlayStation 5 Standard B Chassis (16,990)
                Sony DualSense Wireless Controller (2,390)
              `
            temp_price = '19380'
            break
          case 443:
            val = `
                Sony PlayStation 5 Digital Edition B Chassis (13,990)
                Sony DualSense Wireless Controller Midnight Black (2,390)
              `
            temp_price = '16380'
            break
          case 780:
            // val = `
            //     Sony PlayStation 5 Standard B Chassis (16,990)
            //     Sony DualSense Wireless Controller (2,390)
            //     &nbsp;
            //     &nbsp;
            //     &nbsp;
            //   `
            val = `
                Bundle Set 1
              `
            temp_price = '19380'
            break
          case 781:
            // val = `
            //     Sony PlayStation 5 Standard B Chassis (16,990)
            //     Sony DualSense Wireless Controller (2,390)
            //     PlayStation PS5-G : Gran Turismo 7 STD (2,290)
            //     PlayStation PS5-G : Horizon Forbidden West (2,290)
            //     Thrustmaster Gaming Controller T300 RS GT V2 Black (18,500)
            //   `
            val = `
                Bundle Set 3
              `
            temp_price = '42460'
            break
          case 782:
            // val = `
            //     Sony PlayStation 5 Digital Edition B Chassis (13,990)
            //     Sony DualSense Wireless Controller (2,390)
            //     Thrustmaster Gaming T248 PS Version (15,990)
            //     Sony Media Remote PS5 (1,090)
            //   `
            val = `
                Bundle Set 2
              `
            temp_price = '33460'
            break
        }

        if (val) {
          item.name_bundle = val
            .split('\n')
            .map(v => v.trim())
            .filter(v => v !== '')
            .join('<br>')
          item.price_bundle = temp_price
        }

        return item
      })

      return items
    },
  },

  data() {
    return {
      //   model: [
      //     { name: 'PlayStation®5 Digital Edition', code: '1' },
      //     { name: 'PlayStation®5', code: '2' },
      //   ],
    }
  },

  created() {
    this.doAutoSelect()
  },

  watch: {
    modelItems() {
      this.doAutoSelect()
    },
  },

  methods: {
    onClick(item) {
      /// AUTO SELECT ///
      this.$store.dispatch('model/selectModel', item)
      this.$store.dispatch('bundle/reset')
    },

    txtDisplaySize(item) {
      return item.model[0].data[0].display_size
    },

    txtPrice(item) {
      return item.model[0].data[0].price
    },

    isSelected(item) {
      return this.selected?.id === item?.id
    },

    isDisabled(item) {
      // TODO: check nesting stock
      return !item.active
    },

    doAutoSelect() {
      // const item = this.modelItems[0]
      const item =
        this.modelItemsFirstLine?.[0] ?? this.modelItems?.[0]
      if (item) {
        this.$store.dispatch('model/selectModel', item)
        this.$store.dispatch('bundle/reset')
      }
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl'

.model-content
  display flex
  justify-content center
  flex-wrap wrap
  width 80%
  @media medium
    width 100%
  @media small
    width 100%


.model-item
        width 50%
        padding 1rem

@media medium
    .model-item
        width 100%
        padding 0.75rem 0

@media small
    .model-item
        width 100%
        padding 0.75rem 0

.col-model {
  position: relative;
}

.btn-model {
  color: $color-dark-gray;
  display: flex;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  border-radius: 4px;
  border: 1px solid;
  flex-direction column
  border-color: $color-bnn-border-dark-btn;
  justify-content: center;
  align-content: items-center;
  // min-height: 203px;
  width 100%;
  height 100%;
  gap 2rem;

  ul {
    text-align: left;
    margin-top: 15px;
    margin-left: 10px;

    li:last-child {
      position: absolute;
      list-style: none;
      bottom: -60px;
      left: 20%;
      width: 60%;
      text-align: center;
      background: #ffd400;
      border-radius: 10px;
      padding: 5px 0;
    }
  }

  &:hover {
    border-color: $color-dark-gray;
  }

  // .selected
  // border-color: $color-dark-gray
  text-align: center;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  cursor: pointer;

  &.selected {
    border-color: $color-bnn-border;
    box-shadow: 0px 2px 3px #ffd400;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;

    &:hover {
      border-color: $color-gray-border !important;
    }
  }
}
</style>
