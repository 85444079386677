var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',_vm._b({staticClass:"flex-column text-center py-6 mb-2 mt-2 w-full"},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('div',{staticClass:"font-semibold"},[_c('h2',{staticClass:"text-2xl"},[_vm._v("เลือกรุ่น")])]),_c('div',{staticClass:"text-3xl"},[_vm._v("PlayStation®5")]),_c('div',{staticClass:"text-md"},[_vm._v(" รับประกันตัวเครื่อง 1 ปี Sony Thailand ")])]),_c('v-col',[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"model-content"},_vm._l((_vm.modelItemsFirstLine),function(item,index){return _c('div',{key:item.code,ref:item.code,refInFor:true,staticClass:"model-item",attrs:{"id":`model-${index}`}},[_c('div',{staticClass:"btn-model",class:{
              selected: !_vm.isDisabled(item) && _vm.isSelected(item),
              disabled: _vm.isDisabled(item),
            },on:{"click":function($event){_vm.isDisabled(item) ? false : _vm.onClick(item)}}},[_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"col-model"},[_c('div',{staticClass:"text-lg font-semibold",domProps:{"innerHTML":_vm._s(item.name_bundle || item.name)}})])],1),_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"p-4 text-lg align-self-center"},[_vm._v(" ราคา "+_vm._s(_vm._f("currency")((item.price_bundle || item.price)))+" - ")])],1)],1)])}),0)]),_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"model-content"},_vm._l((_vm.modelItemsOtherLine),function(item,index){return _c('div',{key:item.code,ref:item.code,refInFor:true,staticClass:"model-item",attrs:{"id":`model-${index}`}},[_c('div',{staticClass:"btn-model",class:{
              selected: !_vm.isDisabled(item) && _vm.isSelected(item),
              disabled: _vm.isDisabled(item),
            },on:{"click":function($event){_vm.isDisabled(item) ? false : _vm.onClick(item)}}},[_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"col-model"},[_c('div',{staticClass:"text-lg font-semibold",domProps:{"innerHTML":_vm._s(item.name_bundle || item.name)}})])],1),_c('v-row',_vm._b({},'v-row',{ justify: 'center', 'no-gutters': true },false),[_c('v-col',{staticClass:"p-4 text-lg align-self-center"},[_vm._v(" ราคา "+_vm._s(_vm._f("currency")((item.price_bundle || item.price)))+" - ")])],1)],1)])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }