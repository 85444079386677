<template>
  <v-dialog v-model="dialog" width="600">
    <div class="wrap">
      <span class="text-close">
        <v-icon color="white" @click="$emit('onClose')"
          >mdi-close</v-icon
        >
      </span>
      <v-card class="pt-8">
        <v-card-text>
          <v-row
            v-bind="{ justify: 'center', 'no-gutters': true }"
            class="w-full py-6"
          >
            <img
              class="img-soldout"
              src="@/assets/playstation/Sold_out.svg"
          /></v-row>
          <v-row
            v-bind="{ justify: 'center', 'no-gutters': true }"
            class="w-full"
          >
            <h2 class="text-2xl text-bnn-red text-center">
              สินค้าหมด
            </h2>
          </v-row>
          <v-row
            v-bind="{ justify: 'center', 'no-gutters': true }"
            class="w-full mb-6 mt-2"
          >
            <span class="text-md" v-html="message"> </span>
          </v-row>
          <v-row
            v-bind="{ justify: 'center', 'no-gutters': true }"
            class="w-full"
          >
            <div
              class="bnn-confirm-out-stock"
              @click="onClickClose()"
            >
              ตกลง
            </div>
          </v-row>
        </v-card-text>

        <!-- <v-divider></v-divider> -->

        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            I accept
          </v-btn>
        </v-card-actions> -->
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    dialog: Boolean,
    message: String,
  },

  methods: {
    onClickClose() {
      this.$emit('onClose')
    },
  },
}
</script>

<style lang="stylus">
@import '../../assets/css/style.styl'

.text-bnn-red
    color $color-bnn-red

.bnn-confirm-out-stock
    background $color-bnn
    border-radius: 30px;
    padding 0.5rem 0
    font-weight bold
    width 200px;
    text-align center
    margin 1rem
    cursor pointer
.img-soldout
    width 50%
.v-dialog
    box-shadow none !important
.v-icon.v-icon
    justify-content flex-end !important
    width 100% !important
</style>

<style lang="stylus" scoped>
.wrap
  overflow hidden

.text-close
    cursor pointer
</style>
