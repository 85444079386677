<template>
  <div>
    <div v-show="isReady">
      <Slider />
      <div data-aos="fade-up">
        <Model />
      </div>
      <div data-aos="fade-up">
        <Bundle />
      </div>
      <CustomerForm />
      <Footer />
    </div>
    <div v-show="!isReady">
      <p>....</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import Slider from '../components/PlayStation/Slider'
import Model from '../components/PlayStation/Model'
import Bundle from '../components/PlayStation/Bundle'
import CustomerForm from '../components/PlayStation/CustomerForm'
import Footer from '../components/PlayStation/Footer'

export default {
  name: 'PlayStation',

  data: () => ({
    isReady: false,
  }),

  computed: {
    ...mapState({
      isIncoming: state => state.model.isIncoming,
    }),
  },

  async mounted() {
    const result = await axios.get(`/campaigns/ps5`)
    const { status } = result.data.data
    if (status === 'expired') {
      this.$router.push({
        name: 'Error',
        params: { error: 'expired' },
      })
    }
    // console.log('status', status)
    await this.$store.dispatch('model/getModel', true)

    // goto incoming page
    if (this.isIncoming) {
      this.$router.push({ name: 'Incoming' })
      return
    }

    // await this.$store.dispatch('model/reset')
    // await this.$store.dispatch('bundle/reset')
    document.body.style.overflowY = ''
    document.querySelector('html').style.overflowY = ''
    this.isReady = true
  },

  components: {
    Slider,
    Model,
    Bundle,
    CustomerForm,
    Footer,
  },
}
</script>
